import React, { useState, useEffect } from 'react';
import { Cookies } from 'react-cookie-consent';

import Button from '../../atoms/button/button';

import styles from './CookieConsentButton.module.css';


const cookieName = 'gatsby-gdpr-google-tagmanager';

const CookieConsentButton = () => {
  const [cookie, setCookie] = useState(() => {
    if (Cookies.get(cookieName)) {
      return Cookies.get(cookieName) === 'true' ? true : false;
    }
    return null;
  });

  const toggleCookie = () => {
    setCookie(!cookie);
  }

  useEffect(() => {
    switch (cookie) {
      case true:
        Cookies.set(cookieName, true);
        break;
      case false:
        Cookies.set(cookieName, false);
        Cookies.remove('_ga');
        Cookies.remove('_gid');
        Cookies.remove('_gat');
        break;
      default:
    }
  }, [cookie]);

  return (
    <Button className={styles.cookieConsentButton} tagName="button" htmlType="button" onClick={toggleCookie}>
      {cookie ? <>Google Analytics deaktivieren</> : <>Google Analytics aktivieren</> }
    </Button>
  )
}

export default CookieConsentButton;
