import PropTypes from 'prop-types';
import React from 'react';
import { camelCase } from 'lodash';

import styles from "./button.module.css"


const Button = ({ children, tagName, htmlType, href, target, styleType, onClick, disabled, className }) => {

  const buttonStyle = styles[camelCase('style-'+styleType)];
  const TagName = tagName;

  return (
    <TagName
      type={tagName==='button' ? htmlType : null}
      href={href}
      className={[styles.button, buttonStyle, className].join(' ')}
      target={target}
      rel={target === '_blank' ? 'nofollow noreferrer' : null}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </TagName>

  )
}

Button.propTypes = {
  /** Tag name */
  tagName: PropTypes.oneOf(['a', 'button', 'span', 'div']),
  /** htmltype */
  htmlType: PropTypes.oneOf(['submit', 'reset', 'button']),
  /** Link URL */
  href: PropTypes.string,
  /** Link target */
  target: PropTypes.string,
  /** Type */
  styleType: PropTypes.oneOf(['default', 'back', 'cta', 'text']),
  /** onClick event */
  onClick: PropTypes.func,
  /** disabled */
  disabled: PropTypes.bool,
  /** Additional classname for individual styling in the the parent component */
  className: PropTypes.string
}

Button.defaultProps = {
  tagName: 'a',
  htmlType: 'submit',
  href: null,
  target: '_self',
  styleType: 'default',
  onClick: () => {},
  disabled: false,
  className: null
}

export default Button
